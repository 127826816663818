<template>
  <div class="d-flex align-items-center fit-content justify-content-between w-100">
    <v-app-bar-nav-icon variant="text" class="mx-1" @click.stop="toggleRail" />
    <div v-if="config.public.API_BASE_URL !== 'https://api.lettis.cz'" class="fit-content">
      <a class="text-danger" :href="config.public.API_BASE_URL" target="_blank">
        <strong v-if="config.public.API_BASE_URL !== 'https://api.stage.lettis.cz'">
          DEV «<span class="text-success">« {{ config.public.API_BASE_URL }} »</span>» DEV
        </strong>
        <strong v-if="config.public.API_BASE_URL === 'https://api.stage.lettis.cz'">
          STAGE «<span class="text-success">« {{ config.public.API_BASE_URL }} »</span>» STAGE
        </strong>
      </a>
    </div>
    <div class="px-3 d-flex align-items-center gap-4">
      <v-btn density="compact" icon="mdi-magnify" @click="serachModel = true" />
      <LanguageSelect />
      <NotificationDropdown />
      <v-btn
        density="compact"
        :icon="`mdi-${isFullscreen ? 'fullscreen-exit' : 'fullscreen'}`"
        @click="toggleFullscreen"
      />
      <UserMenu />
    </div>
  </div>
  <SearchModal v-model="serachModel" />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useRuntimeConfig } from "nuxt/app";
import SearchModal from "./layout/SearchModal.vue";
import LanguageSelect from "./layout/LanguageSelect.vue";
import NotificationDropdown from "./layout/NotificationDropdown.vue";
import screenfull from "screenfull";
import UserMenu from "./layout/UserMenu.vue";

export default defineComponent({
  components: {
    SearchModal,
    LanguageSelect,
    NotificationDropdown,
    UserMenu,
  },
  props: {
    rail: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:rail"],
  setup(props, { emit }) {
    const authStore = useUserStore();
    const user = computed(() => authStore.user);

    const isFullscreen = ref<boolean>(false);

    const config = useRuntimeConfig();

    const serachModel = ref<boolean>(false);

    const toggleFullscreen = () => {
      if (screenfull.isEnabled) {
        screenfull.toggle();
        isFullscreen.value = !screenfull.isFullscreen;
      } else {
        alert("Fullscreen mode is not supported by your browser.");
      }
    };

    const toggleRail = () => {
      emit("update:rail");
    };

    return {
      config,
      serachModel,
      toggleFullscreen,
      isFullscreen,
      user,
      toggleRail,
    };
  },
});
</script>
