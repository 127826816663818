<template>
  <v-dialog v-model="innerModelValue" width="500px">
    <v-card width="500px" class="p-2">
      <v-text-field
        v-model="searchString"
        prepend-inner-icon="mdi-magnify"
        label="Hledaný uživatel, instalace, .."
        outlined
        clearable
        variant="outlined"
        color="primary"
        density="compact"
      ></v-text-field>

      <v-divider class="border-opacity-100 my-0 pb-1" color="gray"></v-divider>

      <v-card-actions>
        <v-btn color="primary" variant="flat" @click="serach">Vyhledat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const searchString = ref<string>("");
    const innerModelValue = computed({
      get: () => props.modelValue,
      set: (vol) => {
        ctx.emit("update:modelValue", vol);
      },
    });

    const serach = () => {};

    return {
      innerModelValue,
      searchString,
      serach,
    };
  },
});
</script>
