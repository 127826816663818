<template>
  <div>
    <v-menu location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" density="compact" icon>
          <img :src="`/images/flags/${currentLocale}_flag.jpg`" alt="img" class="rounded-circle header-link-icon" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in locales"
          :key="`lang-${item.code}`"
          :active="item.code === currentLocale"
          active-class="active"
          @click="changeLanguage(item.code)"
        >
          <div class="d-flex py-2 px-3 w-120px justify-content-between">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <img :src="`/images/flags/${item.code}_flag.jpg`" alt="img" class="rounded-circle header-link-icon" />
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSwitchLocalePath } from "#i18n";

interface Locale {
  code: string;
  name: string;
}

export default defineComponent({
  name: "LanguageSelect",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const switchLocalePath = useSwitchLocalePath();

    const currentLocale = computed(() => (route.path.split("/")[1].length === 2 ? route.path.split("/")[1] : "cs"));

    const locales = ref<Locale[]>([
      { code: "cs", name: "Čeština" },
      { code: "en", name: "English" },
      { code: "de", name: "German" },
    ]);

    const changeLanguage = (code: string) => {
      const path = switchLocalePath(code);
      router.push(path);
    };

    return { locales, changeLanguage, currentLocale };
  },
});
</script>

<style scoped>
.header-element {
  display: flex;
  align-items: center;
}
.header-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.header-link-icon {
  width: 24px;
  height: 24px;
}
.me-2 {
  margin-right: 0.5rem;
}

.w-120px {
  width: 120px;
}

.active {
  background: #df5a5a;
  color: white;
}
</style>
