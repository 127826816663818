<template lang="html">
    <!-- Scroll To Top -->
    <div ref="scrollToTop" class="scrollToTop">
        <span class="arrow"><i class="ri-arrow-up-s-fill fs-20"></i></span>
    </div>
    <!-- Scroll To Top -->
</template>
<script>
export default {
    mounted() {
        window.addEventListener("scroll", this.onScroll);
        const scrollToTop = this.$refs["scrollToTop"];
        scrollToTop.onclick = () => {
            window.scrollTo(0, 0);
        };
    },
    unmounted() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            const scrollToTop = this.$refs["scrollToTop"];
            if (scrollToTop) {
                if (window.scrollY > 100) {
                    scrollToTop.style.display = "flex";
                } else {
                    scrollToTop.style.display = "none";
                }
            }
        },
    },
};
</script>
<style lang=""></style>
