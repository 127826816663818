<template>
  <v-list-group v-if="item.items && item.items.length">
    <template #activator="{ props }">
      <v-list-item
        :disabled="rail"
        v-bind="props"
        rounded="lg"
        :value="item.title"
        density="comfortable"
        class="px-2 menu-title"
      >
        <template #prepend>
          <i :class="`${item.icon} fs-20 side-menu__icon`" />
        </template>
        <v-list-item-title v-if="!rail">{{ item.title }}</v-list-item-title>
      </v-list-item>
    </template>
    <MenuItem
      v-for="(subItem, subItemIndex) in formattedItems"
      :key="`${item.title}-${subItemIndex}`"
      :item="subItem"
      :rail="rail"
      :indent-level="indentLevel + 1"
    />
    <v-list-item
      :to="localeRoute(`${item.path}`)"
      rounded="lg"
      density="comfortable"
      class="d-flex justify-content-center align-items-center px-2 menu-title"
    >
      <v-list-item-title class="fw-bold">Zobrazit vše</v-list-item-title>
    </v-list-item>
  </v-list-group>

  <v-list-item
    v-else
    rounded="lg"
    :value="item.title"
    density="comfortable"
    class="px-2 menu-title"
    :active="isActive(item.id)"
    :to="
      localeRoute({
        name: `${item.path}${item.id ? `-${item.path === 'users' ? 'update' : 'detail'}-id` : ''}`,
        params: { id: item.id },
      })
    "
  >
    <template #prepend>
      <i :class="`${item.icon} side-menu__icon fs-20`" />
    </template>
    <v-list-item-title v-if="!rail" style="margin-left: 0.25rem">{{ item.title }}</v-list-item-title>
    <template v-if="item.id" #append>
      <NuxtLink
        :to="
          localeRoute({
            name: `${item.path}-update-id`,
            params: { id: item.id },
          })
        "
      >
        <v-icon color="secondary" size="sm" icon="mdi-pencil" />
      </NuxtLink>
      <v-icon class="delete" size="sm" icon="mdi-delete" @click="deleteItem(item)" />
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { MenuItem } from "@/interfaces/menu";
import { PropType, defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useLocaleRoute } from "@/.nuxt/imports";
import { toast } from "vue3-toastify";
import { useNuxtApp } from "nuxt/app";
import { useMenuStore } from "@/stores/menu";
import dayjs from "dayjs";

export default defineComponent({
  name: "MenuItem",
  props: {
    item: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
    rail: {
      type: Boolean,
      required: true,
    },
    indentLevel: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const indentSize = computed(() => `${props.indentLevel * 6}px`);
    const route = useRoute();
    const localeRoute = useLocaleRoute();
    const { confirm } = useModalController();
    const { getDynamicItems } = useMenuStore();
    const { t } = useI18n();
    const { $api } = useNuxtApp();

    const getIdFromRoute = computed(() => {
      const match = route.path.match(/(?:detail-|update-)([a-f0-9-]+)/);
      return match ? match[1] : null;
    });

    const isActive = (id?: string) => {
      if (id) return getIdFromRoute.value === id;
    };

    const deleteDevice = async (id: string) => await $api.devices.deleteDevice(id);
    const deleteUser = async (id: string) => await $api.users.deleteUsers(id);

    const deleteItem = async (item: MenuItem) => {
      const value = await confirm?.({
        props: {
          title: t("global.confirm.delete", { variable: item.title }),
          okTitle: t("global.button.yes"),
          cancelTitle: t("global.button.no"),
        },
      });

      if (!item.id) return;
      if (value === true) {
        const { status, error } = await (item.path === "devices" ? deleteDevice(item.id) : deleteUser(item.id));
        if (status.value !== "success") {
          toast.error(error.value.data.detail, {
            theme: "auto",
            icon: true,
            hideProgressBar: true,
            autoClose: true,
            position: "top-right",
          });
        } else {
          toast.success(t("global.form.response.delete.success"), {
            theme: "auto",
            icon: true,
            hideProgressBar: true,
            autoClose: true,
            position: "top-right",
          });
          await getDynamicItems();
        }
      }
    };

    const formattedItems = computed<MenuItem[]>(() => {
      if (!props.item.items) return [];
      const items = [...props.item.items];
      items.sort((a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf());

      return items.slice(0, 3);
    });

    return {
      indentSize,
      isActive,
      localeRoute,
      deleteItem,
      formattedItems,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.menu-title {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.side-menu__icon {
  margin-right: 0.25rem;
}

.v-list-group__items .v-list-item {
  padding-inline-start: v-bind(indentSize) !important;
}

.delete {
  color: $danger;
}
</style>
