<template lang="html">
  <!-- Footer Start -->
  <footer class="footer mt-auto py-3 bg-white text-center">
    <div class="container">
      <span class="text-muted">
        Copyright © <span id="year">LETTI CORP s.r.o.</span>. Created by
        <a href="https://www.agmepro.cz" target="_blank">
          <span class="fw-semibold text-primary text-decoration-underline">AgMePro s.r.o.</span>
        </a>
        All rights reserved
      </span>
    </div>
  </footer>
  <!-- Footer End -->
</template>
<script>
export default {};
</script>
<style lang=""></style>
