<template>
  <v-menu class="header-element notifications-dropdown" transition="slide-y-transition">
    <template #activator="{ props }">
      <v-list-item v-if="user" v-bind="props" prepend-avatar="/images/dummy/1.jpg" class="p-2" rounded="lg">
        <v-list-item-title>
          {{ user.email }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <span class="op-7 d-block fs-11">
            <span v-for="role in user.roles" :key="role">{{ $t("user.role." + role) }}</span>
          </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-list>
      <v-list-item
        :to="localeRoute('profile-settings')"
        :active="$route.path.split('/').includes('settings')"
        active-class="active"
        color="white"
        class="cursor-pointer"
      >
        <div class="d-flex py-2 px-3 w-120px justify-content-between">
          <i class="ti ti-adjustments-horizontal fs-18 me-2 op-7" />
          <v-list-item-title class="ml-2">Nastavení</v-list-item-title>
        </div>
      </v-list-item>
      <v-list-item class="cursor-pointer" @click="logout">
        <div class="d-flex py-2 px-3 w-120px justify-content-between">
          <i class="ti ti-logout fs-18 me-2 op-7" />
          <v-list-item-title class="ml-2">Odhlásit</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useLocaleRoute } from "@/.nuxt/imports";

export default defineComponent({
  name: "NotificationDropdown",
  setup() {
    const authStore = useUserStore();
    const user = computed(() => authStore.user);

    const logout = () => authStore.logUserOut();

    const localeRoute = useLocaleRoute();

    return {
      user,
      localeRoute,
      logout,
    };
  },
});
</script>

<style lang="scss" scoped>
.active {
  background: #df5a5a;
  color: white;
}
</style>
