export interface Notification {
  id: number;
  icon: string;
  iconBg: string;
  title: string;
  description: string;
}

export const notificationList: Notification[] = [
  {
    id: 1,
    icon: "ti ti-gift",
    iconBg: "bg-primary-transparent",
    title: "Your Order Has Been Shipped",
    description: "Order No: 123456 Has Shipped To Your Delivery Address",
  },
  {
    id: 2,
    icon: "ti ti-discount-2",
    iconBg: "bg-secondary-transparent",
    title: "Discount Available",
    description: "Discount Available On Selected Products",
  },
  {
    id: 3,
    icon: "ti ti-user-check",
    iconBg: "bg-pink-transparent",
    title: "Account Has Been Verified",
    description: "Your Account Has Been Verified Sucessfully",
  },
  // {
  //   id: 4,
  //   icon: "ti ti-circle-check",
  //   iconBg: "bg-warning-transparent",
  //   title: "Order Placed ID: #1116773",
  //   description: "Order Placed Successfully",
  // },
  // {
  //   id: 5,
  //   icon: "ti ti-clock",
  //   iconBg: "bg-success-transparent",
  //   title: "Order Delayed ID: 7731116",
  //   description: "Order Delayed Unfortunately",
  // },
];
