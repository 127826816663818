<template>
  <div>
    <v-sheet color="white" height="60px">
      <div class="h-100 d-flex justify-center align-center">
        <NuxtLink :to="localeRoute('/')">
          <LogoIcon v-if="rail" height="32px" :font-controlled="false" alt="logo" class="toggle-logo" />
          <LogoShort v-else height="32px" :font-controlled="false" alt="logo" class="desktop-logo" />
        </NuxtLink>
      </div>
    </v-sheet>

    <v-divider class="border-opacity-100 my-0 pb-1" color="gray"></v-divider>

    <div v-for="(section, sectionIndex) in filteredMenu" :key="`section-${sectionIndex}`" :class="{ px: rail }">
      <v-list v-model:opened="opened" density="comfortable" class="py-0">
        <div :class="!rail && `px-3`">
          <v-list-subheader v-if="section.title && !rail" class="section-title">{{ section.title }}</v-list-subheader>
          <v-divider v-if="rail && section.title" class="border-opacity-100 my-1" color="gray"></v-divider>

          <MenuItem
            v-for="(item, itemIndex) in section.items"
            :key="`item-${sectionIndex}-${itemIndex}`"
            :item="item"
            :rail="rail"
            :indent-level="1"
          />
        </div>
      </v-list>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watchEffect } from "vue";
import { useMenuStore } from "@/stores/menu";
import { useUserStore } from "@/stores/user";
import LogoIcon from "@/assets/svg/logo/logo_icon.svg";
import LogoShort from "@/assets/svg/logo/logo_short.svg";
import MenuItem from "./MenuItem.vue";
import { useLocaleRoute } from "../../.nuxt/imports";

export default defineComponent({
  name: "SideBarDrawer",
  components: {
    LogoIcon,
    LogoShort,
    MenuItem,
  },
  props: {
    rail: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const menuStore = useMenuStore();
    const authStore = useUserStore();
    const menu = computed(() => menuStore.menu);
    const user = computed(() => authStore.user);
    const opened = ref<string[]>([]);
    const subOpened = ref<string[]>([]);
    const localeRoute = useLocaleRoute();

    const filteredMenu = computed(() => {
      return menu.value.filter((section) => section.roles.some((role) => user.value?.roles.includes(role)));
    });

    onMounted(() => {
      menuStore.getDynamicItems();
    });

    watchEffect(() => {
      if (props.rail) {
        subOpened.value = opened.value;
        opened.value = [];
      } else {
        opened.value = subOpened.value;
      }
    });

    return {
      menu,
      filteredMenu,
      user,
      opened,
      localeRoute,
    };
  },
});
</script>

<style lang="scss">
.main-content {
  background: #f0f1f7;
}

.header-padding {
  padding: 2px 0px;
}

.section-title {
  color: #536485;
  font-size: 0.6rem;
  font-weight: 600;
  letter-spacing: 0.065rem;
  text-transform: uppercase;
  padding: 0.75rem 1.65rem;
  white-space: nowrap;
  opacity: 0.5;
  position: relative;
}

.v-list-item {
  color: #536485;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  .v-list-item-title {
    font-size: 0.85rem !important;
  }
}

.px {
  padding: 0px 10px;
}
</style>
