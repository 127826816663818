<template>
  <v-menu class="header-element notifications-dropdown" transition="slide-x-transition" location="start">
    <template #activator="{ props }">
      <v-btn v-bind="props" density="compact" icon>
        <v-badge v-if="notifications[0]" color="error" dot>
          <v-icon icon="mdi-bell" />
        </v-badge>
        <v-icon v-else icon="mdi-bell" />
      </v-btn>
    </template>
    <v-list width="350px">
      <div class="d-flex align-items-center justify-content-between w-100 py-3 px-4">
        <span class="h5 font-weight-bolder mb-0">Notifications</span>
        <v-chip size="x-small" color="secondary">{{ notifications.length }} Unread</v-chip>
      </div>
      <v-divider class="border-opacity-100 my-0 pb-1" color="gray"></v-divider>
      <div v-if="notifications.length" class="px-3 py-2">
        <div v-for="(item, index) in notifications" :key="`notification-${item.id}`">
          <v-list-item :subtitle="item.description" :title="item.title" class="py-2 text-wrap">
            <template #prepend>
              <div class="pe-2">
                <v-avatar :class="item.iconBg" size="32">
                  <i style="font-size: 1.15rem" :class="`${item.icon} side-menu__icon`" />
                </v-avatar>
              </div>
            </template>

            <template #append>
              <v-btn icon="mdi-close" size="sm" variant="flat" @click="removeNotification(item)" />
            </template>
          </v-list-item>
          <v-divider
            v-if="notifications.length - 1 !== index"
            class="border-opacity-100 my-0 pb-1"
            color="gray"
          ></v-divider>
        </div>
      </div>
      <div v-else>
        <v-list-item class="p-5 empty-item1">
          <div class="text-center">
            <v-avatar size="64" color="secondary">
              <v-icon>mdi-bell-off-outline</v-icon>
            </v-avatar>
            <h6 class="fw-semibold mt-3">No New Notifications</h6>
          </div>
        </v-list-item>
      </div>
      <v-divider class="border-opacity-100 my-0 pb-1" color="gray"></v-divider>
      <div class="px-3 py-2">
        <v-btn color="primary" class="w-100">View All</v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue";
import { Notification, notificationList } from "@/data/headerdata";

export default defineComponent({
  name: "NotificationDropdown",
  setup() {
    const notifications = ref(notificationList);

    const removeNotification = (item: Notification) => {
      notifications.value = notifications.value.filter((i) => i.id !== item.id);
    };

    return {
      notifications,
      removeNotification,
    };
  },
});
</script>
